var ErrorWindow = (function () {
  
  var instance;
  
  function ErrorWindow(msg, btnText)
  {
    var _this = this;
    this.msg = msg;
    this.btnText = btnText;
    
    if(typeof instance != "undefined")
    {
      this.update();
      return instance;
    }
    else
    {
      instance = this;
      generate();
    }
    
    return _this;
  }
  
  /**
  * エラーウィンドウを作成します
  */
  generate = function () {
    instance.elm = $('<div class="error-window"><div class="error-window__core"><div class="error-window__contents"><p class="error-window__icon-cross"></p><p class="error-window__message">' + instance.msg + '</p></div><div class="error-window__btn-close">' + instance.btnText + '</div></div></div>');
    
    $("body").append(instance.elm);
    $(".error-window__btn-close").on("click", instance.close);
  };
  
  /**
  * エラーウィンドウを表示します
  */
  ErrorWindow.prototype.open = function () {
    instance.elm.show();
    return this;
  };

  /**
  * エラーウィンドウを閉じます
  */
  ErrorWindow.prototype.close = function () {
    instance.elm.hide();
    return this;
  };

  /**
  * エラーウィンドウの内容を更新
  */
  ErrorWindow.prototype.update = function () {
    $(instance.elm).find(".error-window__message").html(this.msg);
    $(instance.elm).find(".error-window__btn-close").html(this.btnText);
    return this;
  };
  
  return ErrorWindow;
})();