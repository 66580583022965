//グローバルオブジェクト
var g = {
  mainHost: $("#mainHost").attr("data-host"),
  //ERROR Window//
  errWindow: new ErrorWindow("通信に失敗しました。<br>再度お試しください。","Close"),
  btnIds: []  //ページ内感情ボタン格納用
}

//remodal設定
window.REMODAL_GLOBALS = {
  NAMESPACE: 'modal',
  DEFAULTS: {
    hashTracking: false
  }
};

//$(function(){
//  if(typeof g.mainHost == "undefined")
//  {
//    $.ajax({
//      type: "GET",
//      url: "/wp-json/common/get-host",
//      xhrFields: {withCredentials: true},
//      dataType: "json",
//      timeout: 10000
//    })
//    .always(function(result){
//      g.mainHost = result.hostname;
//      //cookie,UUIDを取得
//      getSegatoysCookie(onCookieLoadComplete, onCookieLoadFailed);
//    });
//  }
//  else
//  {
//    //cookie,UUIDを取得
//    getSegatoysCookie(onCookieLoadComplete, onCookieLoadFailed);
//  }
//});

//リストページのViewモード設定
if(typeof localStorage["listViewType"] != "undefined")
{
  $(".product-list__items").addClass(localStorage["listViewType"]);
  $("#radio-switch1").removeAttr("checked");
  $("#radio-switch2").attr("checked", "checked");
}
else
{
  $("#radio-switch1").attr("checked", "checked");
}

//text-overflowの判定
$(".product-list__title").each(function(){
  $(this).removeClass("overText");
  var overFlg = checkTextOverflow(this, $(this).find(".product-list__title-inner").get(0));
  if(overFlg) $(this).addClass("overText");
});


//空文字の場合は検索しない
$(document).on("click", "#cse-search-box .common-header__searchicon", function(e){
  if($("#cse-search-box .common-header__input").val() == "")
  {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
});

//cookie,UUIDを取得
//getSegatoysCookie(onCookieLoadComplete, onCookieLoadFailed);

function onCookieLoadComplete(data)
{
  g.cookieObj = data;
  g.uuid = data.uuid;
  btnInit();
}

function onCookieLoadFailed(data)
{
  //console.error(data);
  btnInit();
  return false;
}

//感情ボタンのロード//
function btnInit()
{
  $("[class*='btn-interest'][data-id]").each(function(){
    g.btnIds.push($(this).attr("data-id"));
    if(typeof g.pageType == "undefined") g.pageType = $(this).attr("data-toy");
  });
  
  //ボタンがページ内に存在し、且つ、uuidの取得に成功していたらボタンデータを取得
  if(g.btnIds.length > 0 && typeof g.uuid != "undefined")
  {
    getBtnStatus(g.btnIds);
  }
}

/**
 * 感情ボタンステータス問い合わせ
 * @param btns {array} - 取得したいボタンidの配列
 */
function getBtnStatus(btns)
{
  $.ajax({
    type: "POST",
    url: "/wp-json/emotional-button/load-info",
    xhrFields: {withCredentials: true},
    dataType: "json",
    timeout: 10000,
    data: {
      type: g.pageType,
      ids: btns,
      uuid: g.uuid
    }
  })
  .done(function(result){
    var len = result.length;
    for(var i = 0; i < len; i++)
    {
      var btnInfo = result[i];
      var $btn = $("[class*='btn-interest'][data-id='" + btnInfo.id + "']");
      if(btnInfo.isVote) $btn.addClass("active");
      else $btn.removeClass("isChecked active");
      $btn.find("[class*='__score']").text(btnInfo.count);
      $btn.attr("data-label", btnInfo.label);
    }
  })
  .fail(function(result){
    //g.errWindow.open();
    return false;
  });
}

//Slider
if(typeof $.fn["sliderPro"] != "undefined")
{
  //商品画像スライダーの設定
  var option = {
    width: 912,
    height: 480,
    arrows: true,
    buttons: false,
    imageScaleMode: "contain",
    fullScreen: false,
    shuffle: false,
    thumbnailArrows: true,
    thumbnailWidth: 114,
    thumbnailHeight: 60,
    autoplay: false
  };
  
  if($("#product-slider .sp-slide").length == 2)
  {
    option.loop = false;
    $('#product-slider').sliderPro(option);
  }
  else if($("#product-slider .sp-slide").length > 1)
  {
    $('#product-slider').sliderPro(option);
  }
  else
  {
    option.arrows = false;
    option.thumbnailArrows = false;
    option.touchSwipe = false;
    option.thumbnailTouchSwipe = false;
    option.thumbnailWidth = 0;
    option.thumbnailHeight = 0;
    
    $('#product-slider').sliderPro(option);
  }
}

//interest button//
$(document).on("click", ".btn-interest, .btn-interest-mini", function(e){
  e.preventDefault();
  e.stopPropagation();

  var _this = this;
  
  //uuidがなければ再取得//
  if(typeof g.uuid != "undefined") postEmotionalButton(_this);
  else
  {
    //UUIDを取得
    $.ajax({
      type: "GET",
      url: "/wp-json/emotional-button/load-user",
      xhrFields: {withCredentials: true},
      dataType: "json",
      timeout: 10000
    })
    .done(function(result){
      g.uuid = result.uuid;
      postEmotionalButton(_this);
    })
    .fail(function(result){
      g.errWindow.open();
      return false;
    })
  }
});

/**
 * 感情ボタンクリック送信
 * @param btn{HTMLElement} 押下したボタンのelement
 **/
function postEmotionalButton(btn)
{
  $.ajax({
    type: "POST",
    url: "/wp-json/emotional-button/push-button",
    xhrFields: {withCredentials: true},
    dataType: "json",
    timeout: 10000,
    data: {
      type: $(btn).attr("data-toy"),
      id: $(btn).attr("data-id"),
      uuid: g.uuid
    }
  })
  .done(function(result){
    if(result.isVote)
    {
      $(btn).find(".btn-interest__anim-container").each(function(i){
        var random = Math.random() * 60 + i * 120;
        var rotStr = "rotate(" + random + "deg)";
        $(this).css({
          transform: rotStr,
          "-webkit-transform": rotStr,
          "-moz-transform": rotStr,
        });
      });
      $(btn).addClass("isChecked");
    }
    else
    {
      $(btn).removeClass("active isChecked");
    }
    
    $(btn).find("[class*='__score']").text(result.count);
    
    setSegatoysCookie("param", "emotional_button",
      function(d){
        //ボタンの情報をオブジェクトに混ぜる
        d.label = result.label;
        d.id = result.id;
        setSegatoysCookieComplete(d, "emotional_button", btn)
      },
      function(d){setSegatoysCookieFailed(d, "emotional_button", btn)}
    );
    return false;
  })
  .fail(function(result){
    g.errWindow.open();
    return false;
  });
}

//商品一覧のビューモード切り替え
$(".list-switch__btn input").on("click",function(){
  var $this = $(this).attr("id");
  if($this == "radio-switch1")
  {
    $(".product-list__items").removeClass("type2");
    localStorage.removeItem("listViewType");
  }
  else
  {
    $(".product-list__items").addClass("type2");
    localStorage["listViewType"] = "type2";
  }
  
  //text-overflowの判定
  $(".product-list__title").each(function(){
    $(this).removeClass("overText");
    var overFlg = checkTextOverflow(this, $(this).find(".product-list__title-inner").get(0));
    if(overFlg) $(this).addClass("overText");
  });
});

//Sort btn
$(".list-switch__sortbtn").on("click",function(e){
  e.preventDefault();
  e.stopPropagation();
	$(".list-switch__sort-selector").fadeToggle(200);
});

var sort = $('.list-switch__sortbtn').attr('data-sort');
$('.list-switch__sortlist[data-sort="'+ sort + '"]').addClass('active');
$('.list-switch__sorttext').text($('.list-switch__sortlist[data-sort="'+ sort + '"]').text());

if($(".list-switch__sort-selector").length > 0)
{
  $("body").on("click", function(e){
    $(".list-switch__sort-selector").fadeOut(200);
  });
}

//FAQ表示
$(".product-question__label").click(function(){
  $(this).toggleClass("active");
});

//contact
$(".product-question__btn.contact").on("click", function(e){
  e.preventDefault();
  e.stopPropagation();
  var _this = this;
  
  setSegatoysCookie("param", "inquiry",
    function(d){setSegatoysCookieComplete(d, "inquiry", _this)},
    function(d){setSegatoysCookieFailed(d, "inquiry", _this)}
  );
});

//manual
$(".product-question__btn.manual").on("click", function(e){
  e.preventDefault();
  e.stopPropagation();
  var _this = this;
  
  setSegatoysCookie("param", "download",
    function(d){setSegatoysCookieComplete(d, "download", _this)},
    function(d){setSegatoysCookieFailed(d, "download", _this)}
  );
});

//shop
$(".side-contents__link").on("click", function(e){
  e.preventDefault();
  e.stopPropagation();
  var _this = this;
  
  setSegatoysCookie("param", "conversion",
    function(d){setSegatoysCookieComplete(d, "conversion", _this)},
    function(d){setSegatoysCookieFailed(d, "conversion", _this)}
  );
});


//Movie
//Youtube埋め込みプレイヤーがあるかチェック
if($(".movieModal > iframe").length > 0)
{
  //
  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

// 動画読み込み処理
function onYouTubeIframeAPIReady()
{
  //動画ステータス確認用変数
  g.gaPlayers = new Array();
  g.gaMovieState = new Array();
  
  $(".movieModal > iframe").each(function(){
    var _id = this.src.replace(/http.*\//gi, "").replace(/\?.*/, "");
    this.id = _id;
    
    var player = new YT.Player(_id, {
      videoId: _id,
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  });
}

// 動画読み込み時の処理
function onPlayerReady(event) {
  g.gaPlayers.push(event.target);
  var iframeID = event.target.getIframe().id;
  g.gaMovieState[iframeID] = false;
}

// 再生状況に変化があった時の処理
function onPlayerStateChange(event) {
  // 動画を読み込んでいるiframeのID
  var iframeID = event.target.getIframe().id;

  // 再生開始時の処理
  if (event.data == YT.PlayerState.PLAYING)
  {
    if(!g.gaMovieState[iframeID])
    {
      ga('send', 'event', "Youtube", location.href, "movie_id" + iframeID);
    }
    g.gaMovieState[iframeID] = true;
  }

  // 再生完了時の処理
  if (event.data == YT.PlayerState.ENDED)
  {
    g.gaMovieState[iframeID] = false;
  }
}

//動画選択
$(document).on('click', '[data-remodal-target="movieModal"]', function (e) {
  var _id = $(this).attr("data-movieId");
  $(".movieModal iframe").each(function(){
    if($(this).attr("data-movieId") == _id) $(this).show();
    else $(this).hide();
  });
});

//動画モーダルclose
$(document).on('closing', '.remodal', function (e) {
  var _id = $(this).find("iframe:visible").attr("data-movieId");
  var len = g.gaPlayers.length;
  for(var i= 0; i < len; i++)
  {
    g.gaPlayers[i].stopVideo();
  }
});

/**
 * GA通知用文字列を返す
 * @param data {object} cookieとして保持しているオブジェクト
 */
function getCookieString(data)
{
  var _id = data.uuid;
  var _fd = data.first_visit_date;
  var _em = data.emotional_button;
  var _dl = data.download;
  var _eq = data.enquete;
  var _in = data.inquiry;
  var _cv = data.conversion;
  
  var str = "";
  str += "id" + _id + "," + "fd" + _fd + "," + "em" + _em + "," + "dl" + _dl + "," + "eq" + _eq + "," + "cv" + _cv + "," + "in" + _in;
  
  return str;
}

/**
 * cookieのロード
 * @param cb_done {function} 成功時のコールバック
 * @param cb_fail {function} 失敗時のコールバック
 */
//function getSegatoysCookie(cb_done, cb_fail)
//{
//  $.ajax({
//    type: "GET",
//    url: g.mainHost + "/wp-json/common/load",
//    xhrFields: {withCredentials: true},
//    dataType: "json",
//    timeout: 10000
//  })
//  .done(function(result){
//    cb_done(result);
//  })
//  .fail(function(result){
//    cb_fail(result);
//  });
//}

/**
 * cookieの書込み依頼
 * @param key {string} setするkey名
 * @param value {string} setする値
 * @param cb_done {function} 成功時のコールバック
 * @param cb_fail {function} 失敗時のコールバック
 */
function setSegatoysCookie(key, value, cb_done, cb_fail)
{
  var obj = {}
  obj[key] = value;
  
  $.ajax({
    type: "POST",
    url: g.mainHost + "/wp-json/common/set",
    xhrFields: {withCredentials: true},
    dataType: "json",
    timeout: 10000,
    data: obj
  })
  .done(function(result){
    cb_done(result);
  })
  .fail(function(result){
    cb_fail(result);
  });
}

/**
 * cookieの書込み成功ハンドラ
 * @param data {object} サーバーからの返り値
 * @param label {string} GAに紐づくアクションラベル
 * @param elm {htmlElement} イベントをトリガした要素
 */
function setSegatoysCookieComplete(data, label, elm)
{
  //GA送信後の共通コールバック処理
  var gaCallback = {
    hitCallback: function() {
      if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
    },
    hitCallbackFail: function(){
      if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
    }
  };
  
  var dataObj = {
    "event": label,
    "cookieStr": getCookieString(data)
  }
  
  //labelで処理分岐
  switch(label)
  {
    case "emotional_button":
      ga('send', 'event', label, location.href, data.label + data.id);
      dataObj.label = data.label + data.id;
      break;
      
    case "inquiry":
      ga('send', 'event', "inquiry_button", location.href, "https://enquete.segatoys.co.jp/inquiry/", gaCallback);
      break;
      
    case "download":
      ga('send', 'event', "manual", location.href, $(elm).attr("href"), gaCallback);
      break;
      
    case "conversion":
      ga('send', 'event', "buy_link", location.href, $(elm).attr("href"), gaCallback);
      break;
      
    //TODO: アンケートはまだ未実装
    //case "enquete":
    //  
    //  break;
  }

  dataLayer.push(dataObj);
}

/**
 * cookieの書込み失敗ハンドラ
 * @param data {object} サーバーからの返り値
 * @param label {string} GAに紐づくアクションラベル
 * @param elm {htmlElement} イベントをトリガした要素
 */
function setSegatoysCookieFailed(data, label, elm)
{
  //console.error("Failed: Set cookie::" + data);
  
  //labelで処理分岐
  switch(label)
  {
    case "inquiry":
      if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
      break;
      
    case "download":
      if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
      break;
      
    case "conversion":
      if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
      break;
      
    //TODO: アンケートはまだ未実装
    //case "enquete":
    //  if(typeof window.open($(elm).attr("href"), "_blank") == "undefined") location.href = $(elm).attr("href");
    //  break;
  }
}
/**
 * テキストのはみ出しチェック
 * @param _parent {htmlElement} 表示領域となる親要素
 * @param _txtElm {htmlElement} チェックしたいテキストをもつelement
 * @return {boolean} はみ出しているかどうかのフラグ
 */
function checkTextOverflow(_parent, _txtElm)
{
  if($(_txtElm).height() < $(_parent).height()) return false;
  else return true;
}